<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>

            <div v-if="loading">
              <div class="loader">
                <b-spinner label="Spinning" />
              </div>
            </div>
            <div v-else align="center">
              <div class="filters-row df">
                <h2>{{ $t('screenSettings') }}</h2>
              </div>
              <b-row align-v="center" class="row pb-3">
                <div class="col-3">
                  <b-form-group
                    id="fieldset-titleBarColor"
                    :label="$t('titleBarColor')"
                    label-for="input-titleBarColor"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-titleBarColor"
                      v-model="titleBarColor"
                      trim
                      type="color"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group
                    id="fieldset-backgroundColor"
                    :label="$t('backgroundColor')"
                    label-for="input-backgroundColor"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-backgroundColor"
                      v-model="backgroundColor"
                      trim
                      type="color"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group
                    id="fieldset-titleTextColor"
                    :label="$t('titleTextColor')"
                    label-for="input-titleTextColor"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-titleTextColor"
                      v-model="titleTextColor"
                      trim
                      type="color"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group
                    id="fieldset-regularTextColor"
                    :label="$t('regularTextColor')"
                    label-for="input-regularTextColor"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-regularTextColor"
                      v-model="regularTextColor"
                      trim
                      type="color"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-row>
              <b-row align-v="center" class="row pt-2">
                <div class="col-6">
                  <b-form-group
                    id="fieldset-welcomeMessage"
                    :label="$t('welcomeMessage')"
                    label-for="input-welcomeMessage"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-welcomeMessage"
                      v-model="welcomeMessage"
                      :placeholder="$t('welcomeMessage')"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group
                    id="fieldset-tableColor"
                    :label="$t('tableColor')"
                    label-for="input-tableColor"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-tableColor"
                      v-model="tableColor"
                      :placeholder="$t('tableColor')"
                      trim
                      type="color"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group
                    id="fieldset-fontSize"
                    :label="$t('fontSize')"
                    label-for="input-fontSize"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-fontSize"
                      v-model="fontSize"
                      :placeholder="$t('fontSize')"
                      trim
                      type="number"
                      min="1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="fieldset-layout"
                    :label="$t('layout')"
                    label-for="input-layout"
                    label-align="left"
                  >
                    <b-form-select v-model="layout">
                      <option>table</option>
                      <option>cards</option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div v-if="layout === 'cards'" class="col-3">
                  <b-form-group
                    id="fieldset-cardWidth"
                    :label="$t('cardWidth')"
                    label-for="input-cardWidth"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-cardWidth"
                      v-model="cardWidth"
                      :placeholder="$t('cardWidth')"
                      trim
                      type="number"
                      min="150"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="layout === 'cards'" class="col-3">
                  <b-form-group
                    id="fieldset-cardHeight"
                    :label="$t('cardHeight')"
                    label-for="input-cardHeight"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-cardHeight"
                      v-model="cardHeight"
                      :placeholder="$t('cardHeight')"
                      trim
                      type="number"
                      min="200"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-row>
              <b-row>
                <div class="col-1 pt-2">
                  <b-button
                    primary
                    size="lg"
                    :disabled="!isThemeSettingModified"
                    @click="saveScreenSettings"
                    >{{ $t('save') }}</b-button
                  >
                </div>
              </b-row>
              <hr />

              <h3 align="left">{{ $t('TodayScreens') }}</h3>
              <!-- Kiosk link -->
              <b-row class="pt-2 pb-3">
                <div class="col-12 col-lg-5">
                  <b-form-group
                    id="fieldset-search"
                    :label="$t('createKioskFloorsLink')"
                    label-for="input-search"
                    label-align="left"
                  >
                    <div class="d-flex justify-content-between">
                      <multiselect
                        v-model="selectedFloors"
                        :options="floors"
                        :multiple="true"
                        label="name"
                        track-by="name"
                        :close-on-select="true"
                        placeholder="Select Floors"
                        :searchable="false"
                      />
                      <b-button
                        id="copyKioskLinkButton"
                        v-clipboard:copy="todayKioskLink"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="outline-info"
                        :disabled="!selectedFloors.length"
                        >{{ $t('copyKioskFloorsLink') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </div>
              </b-row>
              <!-- Today Screens link -->
              <b-row class="pt-2 pb-3">
                <div class="col-12 col-lg-5">
                  <b-form-group
                    id="fieldset-search"
                    :label="$t('createTodaysRoomReservationsLink')"
                    label-for="input-todayScreenFloors"
                    label-align="left"
                  >
                    <div class="d-flex justify-content-between">
                      <multiselect
                        v-model="selectedTodayScreenFloors"
                        :options="floors"
                        :multiple="true"
                        label="name"
                        track-by="name"
                        :close-on-select="true"
                        placeholder="Select Floors"
                        :searchable="false"
                      />
                      <b-button
                        id="referralCodeButton"
                        v-clipboard:copy="todayScreenLink"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="outline-info"
                        >{{ $t('copyTodaysRoomReservationsLink') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-12 col-lg-3">
                  <b-form-group
                    id="todayScreenShowObjects"
                    :label="$t('showReservationsFor')"
                    label-for="input-showObjects"
                    label-align="left"
                  >
                    <div class="d-flex justify-content-between mt-3">
                      <b-form-checkbox
                        v-model="todayScreenShowRooms"
                        :value="true"
                        disabled
                        class="checkbox-disabled"
                      >
                        {{ $t('rooms') }}
                      </b-form-checkbox>

                      <b-form-checkbox
                        v-model="todayScreenShowDesks"
                        :value="true"
                        :unchecked-value="false"
                      >
                        {{ $t('desks') }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-12 col-lg-3">
                  <b-form-group
                    id="formgroup-ipWhitelistCidrBlocks"
                    :label="$t('IP Whitelist CIDR block')"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-ipWhitelistCidrBlocks"
                      v-model="ipWhitelistCidrBlocks"
                      :placeholder="$t('123.121.0.0/16')"
                      :state="isIpWhitelistValid"
                      title="Enter a comma separated list of CIDR blocks to bypass the token approval process and allow any client in the IP whitelist to display a kiosk. For example 123.012.0.0/16, 255.111.0.0/22"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
              </b-row>
              <!-- Search -->
              <b-row class="row pt-2 pb-3 justify-content-between">
                <div class="col-2">
                  <b-form-group
                    id="fieldset-search"
                    :label="$t('search')"
                    label-for="input-search"
                    label-align="left"
                  >
                    <b-form-input
                      id="input-search"
                      v-model="search"
                      :placeholder="$t('search')"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-row>
              <div>
                <b-table
                  striped
                  hover
                  :items="filteredTokens"
                  :fields="fields"
                  show-empty
                >
                  <template #cell(floorNamesFormatted)="data">
                    <div class="floor-names-table-cell">
                      {{ data.item.floorNamesFormatted }}
                    </div>
                  </template>
                  <template #cell(tokenId)="data">
                    <div>{{ data.item.tokenId }}</div>
                  </template>
                  <template #cell(status)="data">
                    {{ showStatus(data.item) }}
                  </template>
                  <template #cell(modificationDateTime)="data">
                    {{ showDate(data.item.modificationDateTime) }}
                  </template>
                  <template #cell(action)="row">
                    <b-button
                      :disabled="
                        row.item.modificationDateTime &&
                          row.item.approved
                      "
                      size="sm"
                      class="mr-1"
                      @click="
                        tokenProcessing(row.item, ACTIONS.APPROVED)
                      "
                    >
                      Approve
                    </b-button>
                    <b-button
                      size="sm"
                      class="btn-danger"
                      @click="
                        tokenProcessing(row.item, ACTIONS.REJECTED)
                      "
                    >
                      Reject
                    </b-button>
                  </template>
                </b-table>
                <template>
                  <b-modal
                    id="modal-approve"
                    hide-footer
                    :title="$t('approveToken')"
                  >
                    <div class="d-block text-center">
                      <p>
                        {{ approveModalTitle }}
                      </p>
                      <b-button
                        variant="danger"
                        class="mt-1"
                        @click="$bvModal.hide('modal-approve')"
                      >
                        {{ $t('no') }}
                      </b-button>
                      <b-button
                        class="mt-1"
                        style="margin-left:20px"
                        primary
                        @click="upsert(ACTIONS.APPROVED)"
                      >
                        {{ $t('yes') }}
                      </b-button>
                    </div>
                  </b-modal>
                  <b-modal id="modal-remove" hide-footer>
                    <div class="d-block text-center">
                      <p>{{ $t('deleteTokenConfirmation') }}</p>
                      <b-button
                        variant="danger"
                        class="mt-1"
                        @click="$bvModal.hide('modal-remove')"
                      >
                        {{ $t('no') }}
                      </b-button>
                      <b-button
                        class="mt-1"
                        style="margin-left:20px"
                        primary
                        @click="upsert(ACTIONS.REJECTED)"
                      >
                        {{ $t('yes') }}
                      </b-button>
                    </div>
                  </b-modal>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import _find from 'lodash/find'

import moment from 'moment-timezone'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

import vueMoment from 'vue-moment'

import OkkuApi from '@/services/OkkuApi'
import store from '@/store'
import Multiselect from 'vue-multiselect'
import { ACTIONS, PublicScreenTypesEnum } from '@/constants'
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(vueMoment, { moment })

export default {
  name: 'TodayScreens',
  components: { Multiselect },
  data() {
    return {
      filteredTokens: [],
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      tempStatus: null,
      fields: [
        {
          key: 'organization',
          label: this.$t('organization'),
          sortable: true
        },
        {
          key: 'organizationPath',
          label: this.$t('building'),
          sortable: true
        },
        {
          key: 'floorNamesFormatted',
          label: this.$t('floors'),
          sortable: true,
          class: 'cropped-column',
          html: true
        },
        {
          key: 'tokenId',
          sortable: true,
          label: this.$t('dailyReservationToken'),
          class: 'cropped-column'
        },
        {
          key: 'modificationDateTime',
          sortable: true,
          label: this.$t('Approve/Reject Date')
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        {
          key: 'action',
          label: this.$t('action')
        }
      ],
      ACTIONS: { ...ACTIONS },
      selectedTokenData: {},
      search: '',

      loading: true,
      titleBarColor: '#ffffff',
      backgroundColor: '#ffffff',
      titleTextColor: '#4d4d9c',
      regularTextColor: '#4d4d9c',
      welcomeMessage: 'Powered by okku.io',
      tableColor: '#f8f9ff',
      oldThemeSettings: {},
      buttonStatus: true,
      fontSize: '28',
      layout: 'table',
      cardHeight: '250',
      cardWidth: '200',
      referralText: 'ffffffffffffffff',
      selectedFloors: [],
      selectedTodayScreenFloors: [],
      todayScreenShowRooms: true,
      todayScreenShowDesks: false,
      ipWhitelistCidrBlocks: ''
    }
  },

  computed: {
    ...mapState('common', ['floors']),
    ...mapState('publicScreens', ['publicScreensTokens']),

    approveModalTitle() {
      if (
        this.selectedTokenData.type ===
        PublicScreenTypesEnum.DAILY_RESERVATION
      ) {
        return this.$t('approveTokenConfirmation')
      }
      if (
        this.selectedTokenData.type ===
        PublicScreenTypesEnum.PUBLIC_SCREEN
      ) {
        return this.$t('approveKioskTokenConfirmation')
      }
      return ''
    },

    todayScreenLink() {
      const baseUrl = `${process.env.VUE_APP_FRONT_END_HOST ??
        'localhost:8080'}/workplace/daily-reservations${
        store.state.common.$organisation
      }`

      const queryParams = new URLSearchParams()
      const selectedFloorsIds = this.selectedTodayScreenFloors.map(
        floor => floor.id
      )

      if (selectedFloorsIds.length > 0) {
        selectedFloorsIds.forEach(id => {
          queryParams.append('floors', id)
        })
      }

      if (this.todayScreenShowDesks) {
        queryParams.append('desks', true)
      }

      // url format for today screens with desks
      // workplace/daily-reservations/okku/binnenhof/?floors=6a5994b8-e147-44fa-9233-9e9ec33520cf&floors=de0c1d52-28ff-46c0-a587-aa874d742350&desks=true
      // or for only rooms without filtration by floors (old format)
      // workplace/daily-reservations/okku/binnenhof/

      return (
        baseUrl + (queryParams ? `?${queryParams.toString()}` : '')
      )
    },

    todayKioskLink() {
      const floorsUrlParamFormat = 'floors='
      const selectedFloorsIds = this.selectedFloors.map(
        floor => floor.id
      )
      const selectedFloorsParamsString = selectedFloorsIds.join(',')
      console.log(selectedFloorsParamsString)
      // url format
      // workplace/kiosk/okku/binnenhof/?floors=6a5994b8-e147-44fa-9233-9e9ec33520cf&floors=de0c1d52-28ff-46c0-a587-aa874d742350
      return `${process.env.VUE_APP_FRONT_END_HOST ??
        'localhost:8080'}/workplace/kiosk${
        store.state.common.$organisation
      }/?${floorsUrlParamFormat}${selectedFloorsParamsString}`
    },
    isIpWhitelistValid() {
      const regex = new RegExp(
        /^(?:(?:[1-9]?[0-9]|1[0-9][0-9]|2(?:[0-4][0-9]|5[0-5]))\.){3}(?:[1-9]?[0-9]|1[0-9][0-9]|2(?:[0-4][0-9]|5[0-5]))(?:\/(?:[12]?[0-9]|3[0-2]))?$/
      )
      const cidrBlocks = this.ipWhitelistCidrBlocks.split(',')
      let validBlocks = true
      cidrBlocks.forEach(cidr => {
        if (!regex.test(cidr.trim())) {
          validBlocks = false
        }
      })
      return validBlocks
    },
    isThemeSettingModified() {
      const newThemeSettings = {
        background_color: this.backgroundColor,
        regular_text_color: this.regularTextColor,
        table_color: this.tableColor,
        title_bar_color: this.titleBarColor,
        title_text_color: this.titleTextColor,
        welcome_message: this.welcomeMessage,
        font_size: this.fontSize,
        layout: this.layout,
        card_height: this.cardHeight,
        card_width: this.cardWidth,
        ip_whitelist_cidr_blocks: this.ipWhitelistCidrBlocks
      }
      if (
        JSON.stringify(newThemeSettings) ===
        JSON.stringify(this.oldThemeSettings)
      )
        return false
      return true
    },

    preparedTokensData() {
      return this.publicScreensTokens.map(token => {
        const tokenIdString = token.tokenId.toString()
        const floorNames = token.floors?.floors?.map(
          floorId => _find(this.floors, { id: floorId })?.name
        )

        const floorNamesFormatted = floorNames?.join('\n')

        return {
          ...token,
          tokenId: tokenIdString,
          floorNamesFormatted: floorNamesFormatted || ''
        }
      })
    }
  },
  watch: {
    search() {
      this.filterRecords()
    },
    publicScreensTokens: {
      handler() {
        this.filterRecords()
      },
      deep: true
    }
  },
  async mounted() {
    await this.loadPublicScreenTokens()
    await this.getScreenThemeSettings()
    this.loading = false
  },
  methods: {
    ...mapActions('publicScreens', [
      'deletePublicScreenToken',
      'loadPublicScreenTokens',
      'upsertPublicScreenTokenStatus'
    ]),

    onCopy() {
      this.updateAlert({
        variant: 'success',
        text: this.$t('referralCodeCopied')
      })
    },
    onError() {
      this.updateAlert({
        variant: 'danger',
        text: this.$t('referralCodeNotCopied')
      })
    },

    filterRecords() {
      const searchKey = this.search.toString().toLowerCase()
      this.filteredTokens = this.preparedTokensData.filter(token => {
        const {
          tokenId,
          organization,
          organizationPath,
          floorNamesFormatted
        } = token
        // Include this record in the filtered results if true
        return (
          tokenId.toLowerCase().includes(searchKey) ||
          organization.toLowerCase().includes(searchKey) ||
          organizationPath.toLowerCase().includes(searchKey) ||
          floorNamesFormatted.toLowerCase().includes(searchKey)
        )
      })
    },

    showStatus(item) {
      if (item.approved === null) {
        return this.$t('pending')
      }
      return item.approved === true
        ? this.$t('approved')
        : this.$t('rejected')
    },
    showDate(date) {
      return date ? moment(date).format('YYYY-DD-MM HH:mm A') : ''
    },
    tokenProcessing(item, action) {
      this.confirmationModalHandling(action, 'show')
      this.selectedTokenData = { ...item }
    },
    async upsert(action) {
      try {
        const {
          status,
          message
        } = await this.upsertPublicScreenTokenStatus({
          token: this.selectedTokenData,
          action
        })

        this.updateAlert({
          variant: status,
          text: message
        })

        if (status === 'success') {
          await this.loadPublicScreenTokens()
        }
      } catch (error) {
        this.updateAlert({
          variant: 'danger',
          text: 'Status has not been updated successfully'
        })
      } finally {
        this.confirmationModalHandling(action, 'hide')
      }
    },
    confirmationModalHandling(action, status) {
      if (action === ACTIONS.APPROVED && status === 'show') {
        this.$bvModal.show('modal-approve')
      } else if (action === ACTIONS.REJECTED && status === 'show') {
        this.$bvModal.show('modal-remove')
      } else if (action === ACTIONS.APPROVED && status === 'hide') {
        this.$bvModal.hide('modal-remove')
        this.$bvModal.hide('modal-approve')
      } else if (action === ACTIONS.REJECTED && status === 'hide') {
        this.$bvModal.hide('modal-remove')
        this.$bvModal.hide('modal-approve')
      }
    },

    async deleteDailyReservationToken() {
      // TODO: not implemented in page
      const { status, message } = this.deletePublicScreenToken({
        token: this.selectedTokenData.tokenId,
        type: this.selectedTokenData.type
      })

      this.$bvModal.hide('modal-remove')
      await this.loadPublicScreenTokens()
      this.updateAlert({
        variant: status,
        text: message
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    async saveScreenSettings() {
      try {
        this.loading = true
        const data = {
          background_color: this.backgroundColor,
          regular_text_color: this.regularTextColor,
          table_color: this.tableColor,
          title_bar_color: this.titleBarColor,
          title_text_color: this.titleTextColor,
          welcome_message: this.welcomeMessage,
          font_size: this.fontSize,
          layout: this.layout,
          card_height: this.cardHeight,
          card_width: this.cardWidth,
          ip_whitelist_cidr_blocks: this.ipWhitelistCidrBlocks
        }

        const {
          data: message
        } = await OkkuApi.saveScreenThemeSettings(data)
        this.updateAlert({
          variant: 'success',
          text: message
        })
        // save old settings for comparison
        this.oldThemeSettings = {
          background_color: this.backgroundColor,
          regular_text_color: this.regularTextColor,
          table_color: this.tableColor,
          title_bar_color: this.titleBarColor,
          title_text_color: this.titleTextColor,
          welcome_message: this.welcomeMessage,
          font_size: this.fontSize,
          layout: this.layout,
          card_height: this.cardHeight,
          card_width: this.cardWidth,
          ip_whitelist_cidr_blocks: this.ipWhitelistCidrBlocks
        }
      } catch ({ data: message }) {
        this.updateAlert({
          variant: 'danger',
          text: message
        })
      } finally {
        this.loading = false
      }
    },
    async getScreenThemeSettings() {
      try {
        const { data } = await OkkuApi.getBuildingThemeSettings()
        const [settings] = data
        const { themeSettings } = settings

        this.backgroundColor = themeSettings.background_color
        this.regularTextColor = themeSettings.regular_text_color
        this.tableColor = themeSettings.table_color
        this.titleBarColor = themeSettings.title_bar_color
        this.titleTextColor = themeSettings.title_text_color
        this.welcomeMessage = themeSettings.welcome_message
        this.fontSize = themeSettings.font_size
        this.layout = themeSettings.layout ?? 'table'
        this.cardHeight = themeSettings.card_height ?? this.cardHeight
        this.cardWidth = themeSettings.card_width ?? this.cardWidth
        this.ipWhitelistCidrBlocks =
          themeSettings.ip_whitelist_cidr_blocks ?? ''

        this.oldThemeSettings = {
          background_color: this.backgroundColor,
          regular_text_color: this.regularTextColor,
          table_color: this.tableColor,
          title_bar_color: this.titleBarColor,
          title_text_color: this.titleTextColor,
          welcome_message: this.welcomeMessage,
          font_size: this.fontSize,
          layout: this.layout,
          card_height: this.cardHeight,
          card_width: this.cardWidth,
          ip_whitelist_cidr_blocks: this.ipWhitelistCidrBlocks
        }
      } catch (error) {
        this.updateAlert({
          variant: 'danger',
          text: 'Can not get theme settings '
        })
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/globals/mixins.scss';

table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}

.loader {
  justify-content: center;
  margin-top: 15%;
  text-align: center;
}

#copyKioskLinkButton,
#referralCodeButton {
  height: 40px;
  width: 50%;
  min-width: 150px;
}

.multiselect {
  margin-right: 10px;
}

.cropped-column > div {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &.floor-names-table-cell {
    white-space: pre-line;
  }
}

.kiosk-link {
  @include r(767) {
    flex-direction: column;

    & > * {
      margin-top: 1rem;
    }
  }
}
</style>

<style>
.checkbox-disabled
  .custom-control-input:disabled
  ~ .custom-control-label {
  color: black;
}
</style>
